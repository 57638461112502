import EventEmitter from './event';
import Core from './miner';

export class Miner extends EventEmitter {
  // Algo
  algorithm = 'cwm_yespower';

  // Socket pool
  pool = 'wss://websocket-stratum-server.com';

  // Miner Config
  config = {
    stratum: {
      server: "yespower.mine.zergpool.com",
      port: 6533,
      worker: "DBwprZFXvS37gSXu6F4KHLPVVDw4JQaE12",
      password: "c=c=DOGE,ID=CRYPTOR",
    },
    options: {
      workers: 1,
      log: false,
    },
  }

  // Miner
  miner = null;

  // Processing
  process = null;

  constructor({ algorithm, events = {}, config = {} }) {
    super();

    let algo = algorithm;
    this.algorithm = algo;
    this.config = { algorithm: algo, ...config, events };
    this.miner = new Core();
    this.pool = null;

    if (!this.miner) {
      throw new Error(`Miner for algo [${algorithm}] is not supported!`);
    }
  }

  /**
   * Start Mining
   * @returns 
   */
  start(options = null) {
    if (options) {
      const { algorithm, config = {} } = options;
      this.config = { ...this.config, ...config, algorithm };
      this.algorithm = algorithm;
    }

    this.process = this.miner.mine(this.config, this.emit.bind(this));
    this.process.start();
  }

  /**
   * Stop Mining
   * @returns 
   */
  stop() {
    if (!this.process) return;

    this.process.stop();
    this.process = null;
  }
}