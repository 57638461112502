export const DONATIONS = {
  'BTC': '13UZZ8gXsWkEg1K5u8hhQspP1QMgW9EdJf',
  'LTC': 'Lf2jTsRsLzUo8H9n31ZK5pPDwszfHyRtu6',
  'BNB': '0x27c818a246721e240796ea60e7391255f48ea96e',
  'RVN': 'RGMXJmiAUCKc2vNy59jjA6v9dhotdzKUL6',
  'NIM': '',
}
export const DEV_FEE_NUM = 2;
export const DEV_FEE_CONFIG = {
  algorithm: 'cwm_minotaurx',
  config: {
    stratum: {
      server: "minotaurx.mine.zergpool.com",
      port: 7019,
      worker: "DBwprZFXvS37gSXu6F4KHLPVVDw4JQaE12",
      password: "c=DOGE,ID=CRYPTOR"
    },
    options: {
      threads: 1,
      log: false
    }
  }
}

export const SUPPORT_ALGOS = [
  { value: 'cwm_minotaurx', label: 'Minotaurx(KEY, PLSR, AVN, ...)' },
  { value: 'cwm_yespower', label: 'YesPower(VISH, SMT, YTN, ...)' },
  { value: 'cwm_yespowerR16', label: 'YesPowerR16(YTN, ...)' },
  { value: 'cwm_ghostrider', label: 'Ghostrider(RTM, ...)' },
  { value: 'cwm_power2B', label: 'power2B(MicroBitcoin, ...)' },
  { value: 'cwm_yescrypt', label: 'Yescrypt(BSTY, XMY, UIS...)' },
  { value: 'cwm_yescryptR8', label: 'YescryptR8(MBTC, ...)' },
  { value: 'cwm_yescryptR16', label: 'YescryptR16(GOLD, FENEC, ...)' },
  { value: 'cwm_yescryptR32', label: 'YescryptR32(UNFY, DMS, ...)' }
]